import React from "react"

import Navigation from "./navigation"
import Footer from "./footer"

import "../styles/normalize.css"
import "../styles/global.css"

export default function Layout({ children, isNavigationTransparent, isHomePage }) {
	return (
		<div className="siteWrapper">
			<Navigation isNavigationTransparent={isNavigationTransparent} isHomePage={isHomePage} />
			{children}
			<Footer />
		</div>
	)
}
