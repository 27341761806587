import React, { Component } from "react"
import { slide as Menu } from "react-burger-menu"

import styles from "../styles/navigation.module.css"

let settings = require("../settings")

// export default function Navigation() {
class Navigation extends Component {
	constructor(props) {
		super(props);

		this.state=({
			logo: this.props.isHomePage ? 'PoirotLogoDark' : 'PoirotLogoLight',
			menu: this.props.isHomePage ? 'dark' : 'light',
		})
	}

	componentDidMount() {
		if (this.props.isNavigationTransparent) {
			window.addEventListener("scroll", this.listenToScroll)
		} else {
			this.refs.navigationBlockWrapper.style.backgroundColor = "rgba(33,36,37,1)";
		}
	}

	componentWillUnmount() {
		if (this.props.isNavigationTransparent) {
			window.removeEventListener("scroll", this.listenToScroll)
		}
	}

	listenToScroll = () => {
		if (this.props.isNavigationTransparent) {
			if (window.pageYOffset > 50) {
				if (this.props.isHomePage) {
					this.setState({
						logo: 'PoirotLogoLight',
						menu: 'light'
					})
				}
				this.refs.navigationBlockWrapper.style.backgroundColor = "rgba(33,36,37,1)";
			} else {
				if (this.props.isHomePage) {
					this.setState({
						logo: 'PoirotLogoDark',
						menu: 'dark'
					})
				}
				this.refs.navigationBlockWrapper.style.backgroundColor = "rgba(33, 36, 37, 0)";
			}
		}
	}

	render() {
		return (
			<React.Fragment>
				<div className={styles.navigationBlockWrapper}>
					<div className={styles.mobileNavigationBlock}>
						<Menu
							right
							customBurgerIcon={
								this.state.menu === 'dark' ?
								<svg className="darkBurgerIcon"
									height="24"
									viewBox="0 0 24 24"
									width="24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="m0 0h24v24h-24z" fill="none" />
									<path
										d="m3 18h18v-2h-18zm0-5h18v-2h-18zm0-7v2h18v-2z"
										fillRule="evenodd"
										opacity="1"
										fill="#212425"
									/>
								</svg>
								:
								<svg
									height="24"
									viewBox="0 0 24 24"
									width="24"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path d="m0 0h24v24h-24z" fill="none" />
									<path
										d="m3 18h18v-2h-18zm0-5h18v-2h-18zm0-7v2h18v-2z"
										fillRule="evenodd"
										opacity="1"
										fill="#ffffff"
									/>
								</svg>
							}
							customCrossIcon={
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
								>
									<path
										d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
										fill="#ffffff"
									/>
									<path d="M0 0h24v24H0z" fill="none" />
								</svg>
							}
						>
							<ul>
								<li>
									<a href="/">Home</a>
								</li>
								<li>
									<a href="/features">Features</a>
								</li>
								<li>
									<a href="/insights">Insights</a>
								</li>
								<li>
									<a href="/pricing">Pricing</a>
								</li>
								<hr />
								<li>
									<a href={settings.APP_HOST}>Sign in</a>
								</li>
								<a href={settings.APP_HOST + 'register'}>
									<button className="defaultButtonGreen">
										Register
									</button>
								</a>
							</ul>
						</Menu>
					</div>
					<div className={styles.navigationBackgroundLayer} ref="navigationBlockWrapper">
						<div className="sectionWrapper1200">
							<div className={styles.navigationBlock}>
								<div className={styles.logoWrapper}>
									<a href="/">
										{this.props.isHomePage ?
										<img
											src={settings.CDN_HOST + "images/" + this.state.logo + ".svg"}
											alt="Poirot Email Lookup Tool Application Logo - https://poirot.app"
										/>
										:
										<img
											src={settings.CDN_HOST + "images/" + this.state.logo + ".svg"}
											alt="Poirot Email Lookup Tool Application Logo - https://poirot.app"
										/>
										}
									</a>
								</div>
								<div className={this.state.menu === 'dark' ? styles.navigationMenuWrapperHomePage : styles.navigationMenuWrapper}>
									<menu>
										<ul>
											<li>
												<a href="/">Home</a>
											</li>
											<li>
												<a href="/features">Features</a>
											</li>
											<li>
												<a href="/insights">Insights</a>
											</li>
											<li>
												<a href="/pricing">Pricing</a>
											</li>
										</ul>
									</menu>
								</div>
								<div className={this.state.menu === 'dark' ? styles.navigationActionWrapperHomePage : styles.navigationActionWrapper}>
									<menu>
										<ul>
											<li>
												<a href={settings.APP_HOST + 'register'}>Register</a>
											</li>
										</ul>
									</menu>
									<a href={settings.APP_HOST}>
									<button className="defaultButtonBlue">
										Sign in
									</button>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default Navigation
