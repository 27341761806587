import React from "react"
import { Link } from "gatsby"

import styles from '../styles/footer.module.css'

let settings = require('../settings')

export default function Footer() {
    return (
        <div className={styles.footerBlockWrapper}>
        	<div className="sectionWrapper1100">
        		<div className={styles.footerBlock}>
                    <div>
                        <div><img className={styles.footerPoirotLogo} src={settings.CDN_HOST + 'images/PoirotLogoGrayscale.svg'} alt="Poirot Email Lookup Tool Logo - https://poirot.app"/></div>
                        <div><p>Poirot's mission is to make it easier to discover and verify your prospect's email addresses and to ensure great delivery for your email.</p></div>
                        <div className={styles.socialIcons}>
                            <div>
                                <a href="https://twitter.com/PoirotApp">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"/></svg>
                                </a>
                            </div>
                            <div>
                                <a href="https://www.facebook.com/PoirotApp">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 264 512"><path d="M76.7 512V283H0v-91h76.7v-71.7C76.7 42.4 124.3 0 193.8 0c33.3 0 61.9 2.5 70.2 3.6V85h-48.2c-37.8 0-45.1 18-45.1 44.3V192H256l-11.7 91h-73.6v229"/></svg>
                                </a>
                            </div>
                        </div>
                        <div className={styles.copyright}><p>Copyright &copy; {(new Date().getFullYear())} Intuos llc. All rights reserved.</p></div>
                    </div>
                    <div>
                        <div><h4>Product</h4></div>
                        <div><Link to={`/features`}>Features</Link></div>
                        <div><Link to={`/pricing`}>Plans & Pricing</Link></div>
                        <div><Link to={`/api`}>API usage</Link></div>
                    </div>
                    <div>
                        <div><h4>Company</h4></div>
                        <div><Link to={`/insights`}>Insights</Link></div>
                        <div><Link to={`/terms`}>Terms of Service</Link></div>
                        <div><Link to={`/policy`}>Privacy Policy</Link></div>
                    </div>
        		</div>
        	</div>
        </div>
    )
}